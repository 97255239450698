import {Helmet} from 'react-helmet-async'

const Meta = ({ title, description, keywords }) => {
  return (
    <Helmet>
        <title>{title}</title>
        <meta name='description' content={description}/>
        <meta name='keywords' content={keywords}/>
    </Helmet>
  )
}

Meta.defaultProps = {
    title: 'Welcome to proshop',
    description: 'Description',
    keywords: 'electronics, buy electronics, playstation'
}

export default Meta