import { useEffect } from "react"
import { Link, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from "react-redux"
import { Button, Row, Col, Image, ListGroup, Card, ListGroupItem } from 'react-bootstrap'
import { toast } from 'react-toastify'
import CheckoutSteps from '../components/CheckoutSteps'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { useCreateOrderMutation } from '../slices/orderApiSlice'
import { clearCartItems } from '../slices/cartSlice'

const PlaceOrderScreen = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const cart = useSelector(state => state.cart)

  useEffect(() =>{
    if (!cart.ShippingAddress.address){
        navigate('/shipping')
    } else if (!cart.paymentMethod) {
        navigate('/payment')
    }
  },[cart.paymentMethod, cart.ShippingAddress.address, navigate])

  const [createOrder, {isLoading, error}] = useCreateOrderMutation()

  const placeOrderHandler = async () => {
    try {
      const res = await createOrder({
        orderItems: cart.cartItems,
        shippingAddress: cart.ShippingAddress,
        paymentMethod: cart.paymentMethod,
        itemsPrice: cart.itemsPrice,
        shippingPrice: cart.shippingPrice,
        taxPrice: cart.taxPrice,
        totalPrice: cart.totalPrice
      }).unwrap()
      dispatch(clearCartItems())
      navigate(`/order/${res._id}`)
    } catch (error) {
      toast.error(error)
    }
  }

  return (
    <>
        <CheckoutSteps step1 step2 step3 step4/>
        <Row>
            <Col md={8}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <h2>Shipping</h2>
                  <p>
                    <strong>Address:</strong>
                    {cart.ShippingAddress.address}, {cart.ShippingAddress.city} {' '}
                    {cart.ShippingAddress.postalCode}{' '}
                    {cart.ShippingAddress.country}
                  </p>
                </ListGroup.Item>
                <ListGroup.Item>
                  <h2>Payment Method</h2>
                  <strong>Method:</strong>
                  {cart.paymentMethod}
                </ListGroup.Item>

                <ListGroup.Item>
                  <h2>Order Details</h2>
                  {cart.cartItems.length === 0 ? (
                    <Message>Your cart is empty</Message>
                  ) : (
                    <ListGroup variant="flush">
                      {cart.cartItems.map((item, index) => (
                        <ListGroup.Item key={index}>
                          <Row>
                            <Col md={1}>
                              <Image src={item.image}
                              alt={item.image}
                              fluid
                              rounded
                              />
                            </Col>
                            <Col>
                            <Link to={`/products/${item.product}`}>{item.name}</Link>
                            </Col>
                            <Col md={4}>
                              {item.qty} x ${item.price} = ${(item.qty * item.price).toFixed(2)}
                            </Col>
                          </Row>
                        </ListGroup.Item>
                      ))}
                    </ListGroup>

                  )}
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col md={4}>
              <Card>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <h2>Order Summary</h2>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Row>
                      <Col>Items:</Col>
                      <Col>
                      ${cart.itemsPrice}
                      </Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Row>
                      <Col>Shipping:</Col>
                      <Col>
                      ${cart.shippingPrice}
                      </Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Row>
                      <Col>Tax:</Col>
                      <Col>
                      ${cart.taxPrice}
                      </Col>
                    </Row>
                  </ListGroup.Item>

                  <ListGroup.Item>
                    <Row>
                      <Col>Total:</Col>
                      <Col>
                      ${cart.totalPrice}
                      </Col>
                    </Row>
                  </ListGroup.Item>
                  
                  { error && 
                  <ListGroup.Item>
                    <Message variant='danger'>
                        {error}
                      </Message>
                    </ListGroup.Item>
                  }

                  <ListGroup.Item>
                      <Button type="button"
                      className="btn-block"
                      disabled={cart.cartItems.length === 0}
                      onClick={placeOrderHandler}>
                        Place Order
                      </Button>
                      { isLoading && <Loader/>}
                  </ListGroup.Item>
                </ListGroup>
              </Card>
            </Col>
        </Row>
    </>
  )
}

export default PlaceOrderScreen